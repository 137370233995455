import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/Login")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/Login")),
  },
  {
    exact: true,
    path: "/forgetpassword",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Home/ForgotPassword/index.js")
    ),
  },
  {
    exact: true,
    path: "/profile",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/component/Security")),
  },
  {
    exact: true,
    path: "/balance",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/component/Balance")),
  },

  {
    exact: true,
    guard: true,
    path: "/Members",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Members/index")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-member",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Members/AddMemeber")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/kycdetail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Verify/KycDetail")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dasboard/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/kyc",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/ContactUs/KYC")),
  },

  {
    exact: true,
    guard: true,
    path: "/transactiontable",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Transaction/Transactionindex")
    ),
  },

  {
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/VarifyOtp/index")),
  },

  {
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/ResetPassword/index")),
  },

  // {
  //   exact: true,
  //   path: "/Adminprofile",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Dasboard/Adminprofile/Adminprofile")),
  // },
  {
    exact: true,
    guard: true,
    path: "/withdraw",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/withdraw/Withdraw")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/support",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Support/Support")),
  },
  {
    exact: true,
    guard: true,
    path: "/delete",
    layout: DashboardLayout,
    component: lazy(() => import("./component/Deletemodal")),
  },

  {
    exact: true,
    guard: true,
    path: "/details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Support/Details")),
  },

  {
    exact: true,
    guard: true,
    path: "/invest",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Invested/index")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/verify",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dasboard/Verify/index")),
  },

  {
    exact: true,
    guard: true,

    path: "/reset",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dasboard/Reset/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/send",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dasboard/Send/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/admin-withdraw",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Send/Tronwithdraw/tronwithdraw")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/deposit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/Deposit/index")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/tabs",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/MemberTabs/MainTabs")
    ),
  },
  // {
  //   exact: true,
  //   path: "/roadmap2",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Roadmap/RoadMapMob")),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
