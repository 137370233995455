import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";

import { AuthContext } from "src/context/Auth";

import { AiOutlineCaretDown } from "react-icons/ai";

import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Popper,
  List,
  ListItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
    backgroundColor: "hsla(0deg, 0%, 100%, 0) !important",
    backdropFilter: "blur(44px)",
    "& .MuiIconButton-root": {
      padding: "0px",
    },
    [theme.breakpoints.only("xs")]: {},
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  logo1: {
    // width: "164px",
    // cursor: "pointer",
    // height: "30px",
    // paddingLeft: "16px",
    // "@media (max-width: 400px)": {
    //   width: "100px",
    //   height: "20px",
    //   padding: "0",
    // },
    // "@media (max-width: 500px, min-width: 400px)": {
    //   width: "140px",
    //   height: "30px",
    //   padding: "0",
    // },"@media (max-width: 400px)": {
    //   width: "100px",
    //   height: "20px",
    //   padding: "0",
    // },
    // "@media (max-width: 500px, min-width: 400px)": {
    //   width: "140px",
    //   height: "30px",
    //   padding: "0",
    // },
    // "@media (max-width: 1279px)": {
    //   display: "none",
    // },
    [theme.breakpoints.only("xs")]: {
      width: "90px",
    },
  },

  icon: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  Accordian: {
    // width: "600px",
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  handleDialog: {
    "@media (max-width:500px)": {
      minwidth: "200px",
    },
    "& .MuiDialog-paperScrollPaper": { maxHeight: "78vh" },
    "& .walletheading": {
      width: " 500px",
      margin: 0,
      display: "flex",
      alignItems: " center",
      justifyContent: "space-between",
      borderBottom: " 1px solid #cecece",
      padding: " 5px",
      paddingBottom: "20px",
      fontSize: "14px",
      color: "#000",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        width: " 100%  ",
      },
      "& span": {
        position: "absolute",
        bottom: 3,
        right: 5,
        fontSize: "12px",
        color: "#9e9e9e",
      },
    },
    "& .notificationexpand": {
      textAlign: "center",
    },
    "& .MuiDialogContent-root": {
      "@media (max-width:500px)": {
        width: "307px",
      },
      "@media (max-width:380px)": {
        width: "250px",
      },

      "@media (max-width:350px)": {
        width: "210px",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiDialog-container": {
      position: "absolute",
      right: 1,
      top: "6%",
    },
    "& .MuiDialog-scrollPaper": {
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
    },
  },
  namePlace: {
    "& .MuiDialog-container": {
      maxHeight: "78%",
      minWidth: "214",
    },
  },

  newRuleOn: {
    border: "1px solid black",
    width: "300px",
    height: "160px",
    // position: "absolute",
    display: "none",
    alignItems: "center",
    marginTop: "40px",
    top: "50%",
    right: "0%",
  },
  handleHide: {
    display: "none",
  },
  handleShow: {
    display: "block",
  },
  divide: {
    height: "45px",
    margin: "0 32px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 10px",
    },
  },
  btn: {
    "& .MuiIconButton-root": {
      padding: "12px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px",
      },
    },
  },
  text: {
    fontSize: "15px",
    lineHeight: "0px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#2d1639",
    "&:hover": {
      backgroundColor: "#2d1639",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#ffffff",
    height: "31px",
    padding: "0px 0px",
    letterSpacing: "1px",
    // marginLeft: "15px",
    textDecoration: "none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      // padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px !important",
      fontSize: "12px !important",
    },
    "&.activeMenu": {
      color: "#FFF",
    },
    "&[tabIndex]:focus": {
      color: "#FFF",
    },
    "&[tabIndex]:active": {
      color: "#19AAE6",
    },
    "&:hover": {
      color: "#FFF",
    },
  },
  search: {
    marginLeft: "60px",
    width: "auto !important",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      background: "transparent !important",
      height: "47px",
      top: "0px",
      border: "none",
      width: "100%",
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: "5px !important",
    },
  },
  userData: {
    background: "linear-gradient(90deg, #DE1EE9 15.4%, #FF9268 68.73%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "18px",
  },
  topbtn: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
}));
const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData(props) {
  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    logoDrawer,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
    launchButton,
  } = useStyles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const openpopper = Boolean(anchorEl);
  const id = openpopper ? "simple-popper" : undefined;
  const handleClose = () => {
    setOpen(false);
  };
  const [openSearch, setopenSearch] = useState(false);
  const auth = useContext(AuthContext);
  console.log("sljfksjf", auth);
  const myAdminData = auth?.myProfile;
  const [open1, setOpen1] = React.useState(false);
  const handleCloseSearch = () => {
    setopenSearch(false);
  };
  const history = useHistory();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open1) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]); //eslint-disable-line
  const logOut = () => {
    history.push("/login");
    window.localStorage.removeItem("token");
  };
  const HiddenShow = async () => {
    var get = document.getElementById("showNotification");
    get.style.display = "none";
    var get1 = document.getElementById("showNotification1");
    get1.style.display = "none";
    var set = document.getElementById("hideNotification");
    set.style.display = "block";
  };
  return (
    <>
      <Box style={{ cursor: "pointer" }}>
        <img
          src="images/logo_locus.png"
          alt="sfsdg"
          style={{ width: "180px" }}
          className={classes.logo1}
          onClick={() => history.push("/")}
        />
      </Box>

      <Box flexGrow={1} />
      <Badge className={classes.namePlace} color="secondary">
        <Dialog
          open={open}
          onClose={handleClose}
          className={classes.handleDialog}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <p className="headingtext" style={{ color: "#0F212E0F212E" }}>
              Notifications
            </p>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Box id="showNotification" className={classes.handleShow}></Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Badge>

      <Box display="flex" alignItems="center">
        <IconButton onClick={(e) => history.push("/dashboard")}>
          <Badge className={classes.namePlace} color="secondary">
            {auth?.myProfile?.profile_pic ? (
              <Avatar src={auth?.myProfile?.profile_pic} />
            ) : (
              <Avatar src="" />
            )}
          </Badge>
        </IconButton>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Box className={classes.topbtn}>
            <Button
              aria-describedby={id}
              type="button"
              className={menuButton}
              onClick={handleClick}
            >
              {auth?.myProfile?.first_name}&nbsp;{auth?.myProfile?.last_name}
              &nbsp;
              <AiOutlineCaretDown />
            </Button>
          </Box>

          <Popper id={id} open={openpopper} anchorEl={anchorEl}>
            <div className="menuList">
              <List>
                {/* <ListItem onClick={() => history.push("/dashboard")}>
                  Wade Warren
                </ListItem> */}
                <ListItem onClick={() => history.push("/dashboard")}>
                  Exchange
                </ListItem>
              </List>
            </div>
          </Popper>
          <Typography variant="h6" className={classes.userData}>
            {auth?.myProfile?.balance}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
