import axios from "axios";
import Apiconfig from "../Apiconfig";
import { toast } from "react-toastify";

export const getDataAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },

      params: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error---121", error.response.status);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};
export const getDataAPIHandlerPost = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },

      params: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error---121", error.response);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const postDataHandler = async (endPoint, dataToSend, paramsdata) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
      params: paramsdata,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    return error?.response?.data?.responseMessage;
  }
};
export const postDataHandlerData = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    return error?.response?.data?.responseMessage;
  }
};

export const putDataHandler = async (endPoint, dataToSend, idSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },

      data: dataToSend,
      params: idSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};
export const putparticularDataHandler = async (endPoint, idSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },

      params: idSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const deleteDataHandler = async (endPoint, idSend) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: idSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, token) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfig[endPoint],
      headers: {
        token: token,
      },
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};
