import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Slide,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { IoHome } from "react-icons/io5";
import { PieChart as PieChartIcon } from "react-feather";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { BsFillPeopleFill } from "react-icons/bs";
import { RiWallet3Fill } from "react-icons/ri";
import { ImDrawer } from "react-icons/im";
import { GrAtm } from "react-icons/gr";
import { MdOutlineSecurity } from "react-icons/md";
import { ImUserCheck } from "react-icons/im";
import { RiExchangeDollarFill } from "react-icons/ri";
import { RiSendToBack } from "react-icons/ri";
import { AuthContext } from "src/context/Auth";

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: IoHome,
        href: "/dashboard",
      },

      {
        title: "Members",
        icon: BsFillPeopleFill,
        href: "/Members",
      },

      {
        title: "Invest",
        icon: PostAddIcon,
        href: "/invest",
      },
      {
        title: "Deposit",
        icon: RiWallet3Fill,
        href: "/deposit",
      },

      {
        title: "Withdraw",
        icon: ImDrawer,
        href: "withdraw",
      },

      {
        title: "Transactions",
        icon: RiExchangeDollarFill,
        href: "/transactiontable",
      },
      {
        title: "Verify",
        icon: ImUserCheck,
        href: "/verify",
      },
      {
        title: "Reset 2FA",
        icon: MdOutlineSecurity,
        href: "/reset",
      },
      {
        title: "Send",
        icon: RiSendToBack,
        href: "/Send",
      },
      {
        title: "Admin Withdraw",
        icon: RiSendToBack,
        href: "/admin-withdraw",
      },
      {
        title: "Support",
        icon: SupervisorAccountIcon,
        href: "/support",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean()}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#2D1639 !important",
    overflowX: "hidden",
    // background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 242,
    // top: 80,
    overflow: "hidden",
    borderRight: "1px solid #401F51",
    height: "100%",
    background: "#2D1639",
    boxShadow: "1px 1px 0px #401f51",
    // overflowY: "scroll",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logo1: {
    cursor: "pointer",
    paddingLeft: "20px",
    paddingTop: "21px",
  },
  BITBox: {
    // border: "1px solid white",
    // height: "50px",
    background: "#242B66",
    borderRadius: "2px",
  },
  mainBox: {
    padding: "20px",
  },
  Wallet_class: {
    width: "40px",
    height: "40px",
  },
  box1: {
    padding: "10px",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  yourWallet: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "10px",
    marginLeft: "8px",
  },
  ZeroContent: {
    color: "white",
    fontSize: "22px",
    marginTop: "-12px",
    textAlign: "center",
    paddingBottom: "10px",
    fontFamily: "'Inter'",
    "@media(max-width:1280px)": {
      textAlign: "left",
      marginLeft: "56px",
    },
  },
  hoverText: {
    "& svg": {
      marginLeft: "13px",
      color: "#BEB7E1",
      fontSize: "18px",
      "&:hover": {
        color: "#DE1EE9",
      },
    },
  },
  logoutButton: {
    fontSize: "14px",
    color: "#BEB7E1",
    fontWeight: "500",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "6px",

    "&:hover": {
      background: "linear-gradient(90.01deg, #FDA645 3.33%, #FF00CD 99.58%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },

  dailogOpen: {
    borderRadius: "25px",
    padding: "20px",

    "& .MuiDialog-paperWidthSm": {
      padding: "20px 30px",
    },
    "& .MuiPaper-root": {
      color: "#fff",
      background: "#2D1639 !important",
    },
  },
  dialougTitle: {
    padding: "5px 25px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const auth = useContext(AuthContext);

  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const logoutHandler = () => {
    history.push("/");
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("token");
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        className="defalutscroll"
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          style={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box>
              <Box mb={2}>
                <img
                  src="./images/logo_locus.png"
                  alt="image"
                  className={classes.logo1}
                  style={{ width: "200px" }}
                  onClick={() => history.push("/")}
                />
              </Box>

              {sections.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
            </Box>
            <Box className={classes.hoverText}>
              <Button
                fullWidth
                onClick={() => setIsLogout(true)}
                className={classes.logoutButton}
              >
                <ExitToAppIcon />
                &nbsp; &nbsp; Logout
              </Button>
            </Box>
          </PerfectScrollbar>
          {isLogout && (
            <Dialog
              className={classes.dailogOpen}
              open={isLogout}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setIsLogout(false)}
            >
              <DialogContent>
                <Box className={classes.dialougTitle}>
                  <Typography variant="h6">
                    Are you sure want to logout?
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Button
                    className="modelbtn"
                    color="primary"
                    variant="contained"
                    onClick={() => setIsLogout(false)}
                  >
                    No
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    className="modelbtn"
                    color="primary"
                    variant="contained"
                    onClick={logoutHandler}
                  >
                    Yes
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {/* <img
            src="./images/logo_locus.png"
            alt="image"
            className={classes.logo}
            onClick={() => history.push("/")}
          /> */}
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          style={{ width: "42px" }}
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
