const baseUrl = "https://node.bitechchain.com";

// const baseUrl = "https://node-biworld.mobiloitte.org";
// const baseUrl = "http://172.16.1.132:3039";
// const baseUrl = "http://182.72.203.250:1947";
// const baseUrl = "http://172.16.1.132:3039";
// const baseUrl = "http://182.72.203.250:1947";

const base = `${baseUrl}/api/v1`;
const staticData = `${base}/static`;
const admin = `${base}/admin`;
let user = `${base}/user`;

const Apiconfig = {
  adminLogin: `${admin}/adminLogin`,
  forgotPassword: `${admin}/forgotPassword`,
  viewProfile: `${admin}/viewProfile`,
  editProfile: `${admin}/editProfile`,
  verifyLink: `${admin}/verifyLink`,
  resetPassword: `${admin}/resetPassword`,
  listAllUser: `${admin}/listAllUser`,
  viewUser: `${admin}/viewUser`,
  deleteUser: `${admin}/deleteUser`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  transactionList: `${admin}/transactionList`,
  listAllKycs: `${admin}/listAllKycs`,
  viewKyc: `${admin}/viewKyc`,
  approvedOrDeclinedKYC: `${admin}/approvedOrDeclinedKYC`,
  investList: `${admin}/investList`,
  allDepositList: `${admin}/allDepositList`,
  transactionList: `${admin}/transactionList`,
  listAllWithDrawRequest: `${admin}/listAllWithDrawRequest`,
  acceptWithDraw: `${admin}/acceptWithDraw`,
  resetTwoFa: `${admin}/resetTwoFa`,

  memberCount: `${admin}/memberCount`,
  adminDashboard: `${admin}/adminDashboard`,
  withdrawAdminAmount: `${admin}/withdrawAdminAmount`,

  //static
  staticContentList: `${staticData}/staticContentList`,
  viewStaticContent: `${staticData}/viewStaticContent`,
  editStaticContent: `${staticData}/editStaticContent`,

  // Admin management
  findParents: `${admin}/findParents`,
  viewUser: `${admin}/viewUser`,
  updatePermission: `${admin}/updatePermission`,
  editUserProfile: `${admin}/editUserProfile`,
  sendBitFromAdmin: `${admin}/sendBitFromAdmin`,
  deleteUser: `${admin}/deleteUser`,
  addMember: `${admin}/addMember`,
  listAllTickets: `${admin}/listAllTickets`,
  viewThread: `${admin}/viewThread`,
  replyThread: `${admin}/replyThread`,
  closeTicket: `${admin}/closeTicket`,
  // raise a ticket
  raiseAticket: `${user}/raiseAticket`,
  uploadFile: `${admin}/uploadFile`,
  listDepartment: `${user}/listDepartment`,
  ticketinbox: `${user}/inbox`,
  addThread: `${user}/addThread`,
  viewInbox: `${user}/viewInbox`,
  directsTree: `${user}/directsTree`,
  unilevelTree: `${user}/unilevelTree`,
};
export default Apiconfig;
