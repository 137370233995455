export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 42;
export const tokenContract = "0xE4861c8C1A80250e1e280F7F7bbf84736146b867";
export const MarketplaceContract = "0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653";
export const NftTokenAddress = "0x4846666e4013A48647be98AF3FDE33251e679bd2";

export const TRX_USDT = "TFaUjr8AYkvk3y4YfTgDncqR1RWHNZjxvu";
export const TRX_CONTRACT = "TREdTetsQhQijzHipAWCdViByozXeXWWqF";
export const getImageLevel = (key) => {
  switch (key) {
    case "Leader":
      return "/images/level1.png";
    case "Deputy":
      return "/images/level2.png";
    case "Manager":
      return "/images/level3.png";
    case "Ruby":
      return "/images/level4.png";
    case "Silver":
      return "/images/level5.png";
    case "Gold":
      return "/images/level6.png";
    case "Platinum":
      return "/images/level7.png";
    case "Diamond":
      return "/images/level8.png";
    case "Crown":
      return "/images/level9.png";
    default: {
      return "/images/member.png";
    }
  }
};
